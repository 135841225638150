import {useLazyGetAlertsPaginatedQuery} from '../../../redux/api/alerts';
import {useEventContext} from '../../../context/event/EventContext';
import React, {useCallback, useEffect, useState} from 'react';
import {Alert, GetAlertsParams} from '../../../types/api/alerts';
import AlertsEventTable from '../creation/eventAlerts/AlertsEventTable';
import ImportAlertsAreasButton from './ImportAlertsAreasButton';
import {Button, Col, Drawer, Flex, Radio, Row, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {GoogleLayers} from '../../../types/internal/Map';
import {BaseMapContainer} from '../../common/map/BaseMapContainer';
import {BaseMapContext} from '../../../context/baseMap/BaseMapContext';
import EventEditMapRecap from '../../event/map/eventEditMapRecap/EventEditMapRecap';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import AlertPolygons from '../../alerts/alert/alertMap/AlertPolygons';
import {AlertDetails} from '../../../context/alertDetails/AlertDetails';
import {HandleResize} from '../../common/map/handlers/HandleResize';
import MapLoading from "../../common/map/MapLoading";
import AlertEpicenterMarker from "../../alerts/alert/alertMap/AlertEpicenterMarker";
import {useNavigate} from "react-router-dom";
import {TableRowSelection} from "antd/lib/table/interface";

export default function ImportAlerts() {

  const [getData, {data, isFetching}] = useLazyGetAlertsPaginatedQuery();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {t} = useTranslation();
  const navigate = useNavigate()
  const {event} = useEventContext();
  const [selectedAlert, setSelectedAlert] = useState('');
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const {lg} = useBreakpoint();

  useEffect(() => {
    return () => {
      setSelectedAlert('');
      setCurrentStep(1);
    };
  }, []);

  const handleClose = () => {
    setIsDrawerOpen(false);
    setSelectedAlert('');
    setCurrentStep(1);
  };

  const handleGetData = useCallback((params: GetAlertsParams) => {
    if (event?.uuid) {
      getData({...params, event: event.uuid});
    }
  }, [event?.uuid, getData]);

  const rowSelection: TableRowSelection<Alert> = {
    type: 'radio',
    renderCell: (value, record) => <div
      onClick={e => e.stopPropagation()}
    >
      <Radio.Group value={selectedAlert} style={{padding: 12}}>
        <Radio
          value={record.uuid}
          onChange={e => setSelectedAlert(prevState => prevState !== record.uuid ? record.uuid : prevState)}
        />
      </Radio.Group>
    </div>,
    onChange: (selectedRowKeys: React.Key[], selectedRows: Alert[]) => {
      setSelectedAlert(selectedRows.length === 1 ? selectedRows[0].uuid : '');
    },
  };

  return <>
    <Drawer
      title={capitalize(t('events.selectAlertAreas'))}
      width={'100%'}
      open={isDrawerOpen}
      onClose={handleClose}
    >
      {/*step import*/}
      {currentStep === 1 && <div>
        <AlertsEventTable
          tableProps={{
            dataSource: data?.results,
            loading: isFetching,
            rowSelection,
            onRow: (record) => ({
              onClick: (e) => {
                navigate(`/alerts/${record.uuid}`, {
                  state: {
                    path: `/events/${event?.uuid}`,
                    label: `${t('events.event', {count: 1})}`
                  }
                });
              },
              style: {
                cursor: 'pointer',
              },
            })
          }}
          paginationProps={{
            total: data?.count,
          }}
          getData={handleGetData}
        />
        <Row justify={'end'} style={{marginTop: '1rem'}}>
          <Col>
            <Button
              type={'primary'}
              disabled={!selectedAlert}
              onClick={() => setCurrentStep(2)}
            >
              {capitalize(t('events.chooseClusters'))}
            </Button>
          </Col>
        </Row>
      </div>}
      {/*step assign areas to the clusters*/}
      {currentStep === 2 && selectedAlert && (
        <Flex
          vertical={!lg}
          gap={'small'}
          style={{height: 'calc(100% - 130px)'}}
        >
          <BaseMapContext>
            <div style={{width: '100%'}}>
              <Typography.Title
                style={{margin: 0, textAlign: 'center'}}
                level={5}
              >
                {capitalize(t('alerts.alert', {count: 1}))}
              </Typography.Title>
              <BaseMapContainer
                key={`firstMap_calc(${lg ? '100' : '50'}vh  - 130px)`}
                withStyle={{width: '100%', height: `calc(${lg ? '100' : '50'}vh  - ${lg ? '130' : '100'}px)`}}
                withZoom={true}
                withGeoSearch={true}
                withLayerPicker={true}
                withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
                isStandAlone={true}
              >
                <MapLoading/>
                <HandleResize/>
                <AlertDetails alertUuid={selectedAlert}>
                  <AlertPolygons isImportAlert={true}/>
                  <AlertEpicenterMarker/>
                </AlertDetails>
              </BaseMapContainer>
            </div>
            <div style={{width: '100%'}}>
              <Typography.Title
                style={{margin: 0, textAlign: 'center'}}
                level={5}
              >
                {capitalize(t('events.event', {count: 1}))}
              </Typography.Title>
              <BaseMapContainer
                key={`secondMap_calc(${lg ? '100' : '50'}vh  - 130px)`}
                withZoom={true}
                withStyle={{width: '100%', height: `calc(${lg ? '100' : '50'}vh  - ${lg ? '130' : '50'}px)`}}
                withGeoSearch={true}
                withLayerPicker={false}
                withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
                isStandAlone={true}
              >
                <HandleResize/>
                <EventEditMapRecap/>
              </BaseMapContainer>
            </div>
          </BaseMapContext>
        </Flex>)}
    </Drawer>
    <ImportAlertsAreasButton
      onClick={() => setIsDrawerOpen(true)}
    />
  </>;

}