import {apiSlice} from './baseApi';

type GetNote = {
  'uuid': string,
  'text': string,
  'user_edit': string,
  'date_creation': string
}

const notesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getNote: build.query<GetNote, { uuid: string }>({
      query: (args) => `/notes/${args.uuid}/`,
      providesTags: (res, error, arg) =>
        res ? [{type: 'Notes', id: res.uuid}] : [],
    }),
    patchNote: build.mutation<GetNote, { uuid: string, text: string, eventUuid?: string }>({
      query: (args) => {
        const {uuid, ...params} = args;
        return {
          url: `/notes/${uuid}/`,
          method: 'PATCH',
          body: params,
        };
      },
      invalidatesTags: (res, error, arg) => [
        {type: 'Notes', id: 'LIST'},
        {type: 'Notes', id: arg.uuid},
        ...arg.eventUuid ? [{type: 'AssetStatus' as const, id: arg.eventUuid}] : [],
      ],
    }),
  }),
});

export const {usePatchNoteMutation} = notesApi;