import {Button, List, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import EventTypeFromUuid from "../common/icons/EventTypeFromUuid";
import {GetEvent} from "../../types/api/getEventsResult";
import React from "react";
import {Link} from "react-router-dom";
import EventStateTag from "../events/activeEventsList/EventStateTag";
import GrecimTag from "../events/activeEventsList/GrecimTag";
import {useEventsContext} from "../../context/emergenciesMap/EventsContext";

const {Text} = Typography;

export const EventMapDetail = ({event}: { event: GetEvent }) => {

  const {t} = useTranslation()
  const {eventsInMap, selectedEvent, selectEvent, unselectEvent, hideEventFromMap, showEventInMap} = useEventsContext()

  const isEventInMap = eventsInMap.includes(event.uuid)

  return <>
    <EventTypeFromUuid
      uuid={event?.event_type}
      iconStyle={{
        height: '1.5rem',
        width: '1.5rem',
        backgroundColor: event.color_code
      }}
    />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.3rem',
        marginLeft: '0.2rem'
      }}
    >
      <Text strong>
        {event.name}
      </Text>
      <Text>
        {`${event.involved_assets} ${t('events.involvedAssets')}`}
      </Text>
      <Space>
        <EventStateTag eventState={event?.state}/>
        {!!event?.is_special_management && <GrecimTag/>}
      </Space>
      <div>
        <Button
          style={{padding: 0}}
          type={'link'}
          onClick={(e) => {
            e.stopPropagation()
            if (isEventInMap) {
              hideEventFromMap(event.uuid)
              if (selectedEvent === event.uuid) {
                unselectEvent()
              }
            } else {
              selectEvent(event.uuid)
              showEventInMap(event.uuid)
            }
          }}
        >
          {capitalize(t(!isEventInMap ? 'events.mapShow' : 'events.mapHide'))}...
        </Button>
      </div>
    </div>
    <Link
      to={`/events/${event.uuid}`}
    >
      <Button
        style={{marginTop: '1rem'}}
        block
        type={'primary'}>
        {capitalize(t('misc.goToDetail'))}
      </Button>
    </Link>
  </>
}

export const EventsCard = ({event, onSelect, selected = false}: {
  event: GetEvent,
  onSelect: () => void,
  selected?: boolean
}) => {

  return (<List.Item
      onClick={onSelect}
      style={{
        padding: '1rem',
        ...selected && {
          border: '3px solid var(--primary-6, #1890FF)'
        }
      }}
    >
      <EventMapDetail event={event}/>
    </List.Item>
  );
};