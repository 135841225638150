import {Button, Card, Col, Row, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import AlertsToAssociateToEvent from './AlertsToAssociateToEvent';
import AlertsAssociatedToEventTable from './AlertsAssociatedToEventTable';
import {useTranslation} from 'react-i18next';
import React, {useState} from "react";
import MoveAlertsToBeManagedButton from "../../../alerts/MoveAlertsToBeManagedButton";
import {useEventContext} from "../../../../context/event/EventContext";
import {REDUCED_STATES} from "../../../../types/api/getEventsResult";

export default function EventCreationAlertsSection() {
  const {t} = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const {event} = useEventContext()
  const [isRemoveModeActive, setIsRemoveModeActive] = useState(false)

  return <Card>
    <Row
      justify={'space-between'}
      align={'middle'}
      style={{
        marginBottom: '1rem',
      }}
    >
      <Col>
        <Typography.Title level={3}>{capitalize(t('events.alertsConnected'))}</Typography.Title>
      </Col>
      {event?.reduced_state !== REDUCED_STATES.archived && <Col>
        <Space>
          <AlertsToAssociateToEvent/>
          <Button
          disabled={isRemoveModeActive}
            onClick={() => setIsRemoveModeActive(true)}>
            {capitalize(t('actions.removeItem', {item: t('alerts.alert', {count: 2})}))}
          </Button>
        </Space>
      </Col>}
    </Row>
    {isRemoveModeActive && <Row justify={'space-between'} align={'middle'} style={{marginBottom: '1rem'}}>
      <Col>
        <Typography.Text italic>{capitalize(t('alerts.setAlertToBeManagedSelection'))}</Typography.Text>
      </Col>
      <Col>
        <Space>
          <MoveAlertsToBeManagedButton selectedAlerts={selectedRowKeys} afterRemove={() => setSelectedRowKeys([])}/>
          <Button onClick={() => setIsRemoveModeActive(false)}>
            {capitalize(t('alerts.setAlertToBeManagedSelectionStop'))}
          </Button>
        </Space>
      </Col>
    </Row>}
    <AlertsAssociatedToEventTable
      {...event?.reduced_state !== REDUCED_STATES.archived && isRemoveModeActive
      && {
        selectedAlerts: selectedRowKeys,
        setSelectedAlerts: setSelectedRowKeys
      }
      }
    />
  </Card>;
}