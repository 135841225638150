import {apiSlice} from './baseApi';
import {
  ASSET_STATUS,
  CHANGES,
  ClusterPolygonPostParams,
  ClusterPolygonPostResult,
  CreateEventParams,
  GetAssetsOfTheHistoryOfTheEventParams,
  GetAssetsOfTheHistoryOfTheEventResponse,
  GetAssetStatusParams,
  GetAssetStatusResponse,
  GetCheckAssetsChangesParams,
  GetCheckAssetsChangesResponse,
  GetClusterAsset,
  GetClusterOfTheHistoryOfTheEvent,
  GetClusterPolygon,
  GetEvent,
  GetEventDetailHistory,
  GetEventHistoryParams,
  GetEventHistoryPerAssetParams,
  GetEventHistoryPerAssetResponse,
  GetEventHistoryResponse,
  GetEvents,
  GetEventsMap,
  GetEventsParams,
  InvolvedAssetsRelatedOptionsFilterParams,
  PatchEventParams,
  RemoveClusterResponse,
  UpdateAssetClusterPost,
} from '../../types/api/getEventsResult';
import {Cluster} from '../../types/api/clusters';
import {downloadFileResponseHandler} from '../../utils/download';
import {
  CreateCommunicationWithTextEditor,
  CreateCommunicationWithUpload,
  EventCommunication,
  GetEventAssetCommunicationsParams,
  GetEventClusterCommunicationsParams,
  GetEventCommunicationResponse,
  GetEventTemplateMail,
  PatchCommunication,
} from '../../types/api/communications';
import {sanitize} from 'dompurify';
import {AssetsRelatedOptionGet} from './assets';

const eventsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEventsPaginated: build.query<GetEvents, GetEventsParams | void>({
      query: (params) => {
        return {
          url: '/events/',
          ...!!params && {params},
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({uuid}) => ({type: 'Events' as const, id: uuid})),
            {type: 'Events', id: 'LIST'},
          ]
          : [{type: 'Events', id: 'LIST'}],
    }),
    getEventsForInfiniteScrolling: build.query<GetEvents, GetEventsParams>({
      query: (params) => {
        return {
          url: '/events/',
          ...!!params && {params},
        };
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    getEventsForSelectInfiniteScrolling: build.query<GetEvents, GetEventsParams>({
      query: (params) => {
        return {
          url: '/events/',
          ...!!params && {params},
        };
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      merge: (currentCache, newItems, query) => {
        return query.arg.page === 1
          ? newItems
          : {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
      },
      forceRefetch({currentArg, previousArg}) {
        return (currentArg?.page !== previousArg?.page);
      },
    }),
    getEventsNotPaginated: build.query<GetEvent[], GetEventsParams | void>({
      query: (params) => {
        return {
          url: '/events/',
          params: {
            ...params,
            paginate: false,
          },
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.map(({uuid}) => ({type: 'Events' as const, id: uuid})),
            {type: 'Events', id: 'LIST'},
          ]
          : [{type: 'Events', id: 'LIST'}],
    }),
    getEvent: build.query<GetEvent, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/events/${uuid}/`,
      }),
    }),
    createEvent: build.mutation<GetEvent, CreateEventParams>({
      query: (args) => ({
        url: `/events/`,
        method: 'POST',
        body: {...args},
      }),
      invalidatesTags: [{type: 'Events', id: 'LIST'}],
    }),
    patchEvent: build.mutation<GetEvent, PatchEventParams>({
      query: ({uuid, ...params}) => ({
        url: `/events/${uuid}/`,
        method: 'PATCH',
        body: {...params},
      }),
      async onQueryStarted({uuid, ...patch}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          eventsApi.util.updateQueryData('getEvent', {uuid}, (draft) => {
            Object.assign(draft, patch);
          }),
        );
        try {
          const res = await queryFulfilled;
          dispatch(
            eventsApi.util.updateQueryData('getEvent', {uuid}, (draft) => {
              Object.assign(draft, res.data);
            }),
          );
          
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (res, error, arg) =>
        res ? [{type: 'Events', id: 'LIST'}, {type: 'Events', id: arg.uuid}] : [{type: 'Events', id: 'LIST'}],
    }),
    deleteEvent: build.mutation<void, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/events/${uuid}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Events', id: 'LIST'}],
    }),
    getEventClusterTemplates: build.query<Cluster[], { eventUuid: string }>({
      query: ({eventUuid}) => ({
        url: `/events/${eventUuid}/cluster_templates/`,
        params: {
          paginate: false,
        },
      }),
    }),
    getEventClustersPolygonsNotPaginated: build.query<GetClusterPolygon[], {
      eventUuid: string,
      date?: string,
      changes?: CHANGES
    }>({
      query: ({eventUuid, ...params}) => ({
        url: `/events/${eventUuid}/cluster-polygons/`,
        params: {
          paginate: false,
          ...params,
        },
      }),
    }),
    updateEventClustersPolygons: build.mutation<ClusterPolygonPostResult, ClusterPolygonPostParams>({
      query: ({event_uuid, ...polygon}) => ({
        url: `/events/${event_uuid}/new-cluster-polygons/`,
        method: 'POST',
        body: {...polygon},
      }),
    }),
    getEventClustersAssetsNotPaginated: build.query<GetClusterAsset[], {
      eventUuid: string,
      date?: string,
      changes?: CHANGES
    }>({
      query: ({eventUuid, ...params}) => ({
        url: `/events/${eventUuid}/cluster-assets/`,
        params: {
          paginate: false,
          ...params,
        },
      }),
    }),
    updateClusterAssets: build.mutation<UpdateAssetClusterPost, UpdateAssetClusterPost & { eventUuid: string }>({
      query: ({eventUuid, ...params}) => ({
        url: `/events/${eventUuid}/cluster-assets/`,
        method: 'POST',
        body: params,
      }),
    }),
    removeCluster: build.mutation<RemoveClusterResponse, { eventUuid: string, cluster_template: string }>({
      query: ({eventUuid, ...params}) => ({
        url: `/events/${eventUuid}/remove_cluster/`,
        method: 'POST',
        body: params,
      }),
    }),
    saveConfiguration: build.mutation<void, { eventUuid: string, text_note: string | null }>({
      query: ({eventUuid, ...params}) => ({
        url: `/events/${eventUuid}/save_configuration/`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (res, error, arg) => [
        {type: 'EventConfiguration', id: arg.eventUuid},
        {type: 'Events', id: 'LIST'},
        {type: 'EventConfiguration', id: 'LIST'},
      ],
    }),
    getCheckAssetsChangesPaginated: build.query<GetCheckAssetsChangesResponse, GetCheckAssetsChangesParams>({
      query: ({eventUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/check_changes/`,
          ...!!params && {params},
        };
      },
    }),
    getEventHistoryPaginated: build.query<GetEventHistoryResponse, GetEventHistoryParams>({
      query: ({eventUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/history/`,
          ...!!params && {params},
        };
      },
      providesTags: (res, error, arg) => [{type: 'EventConfiguration', id: arg.eventUuid}],
    }),
    getEventDetailHistory: build.query<GetEventDetailHistory, { eventUuid: string, historyUuid: string }>({
      query: ({eventUuid, historyUuid}) => {
        return {
          url: `/events/${eventUuid}/history/${historyUuid}/`,
        };
      },
      providesTags: (res, error, arg) => [{type: 'EventConfiguration', id: arg.eventUuid}],
    }),
    getEventHistoryPerAssetPaginated: build.query<GetEventHistoryPerAssetResponse, GetEventHistoryPerAssetParams>({
      query: ({eventUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/history-per-asset/`,
          ...!!params && {params},
        };
      },
      providesTags: (res, error, arg) => [{type: 'EventConfiguration', id: arg.eventUuid}],
    }),
    getEventAssetStatusPaginated: build.query<GetAssetStatusResponse, GetAssetStatusParams>({
      query: ({eventUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/asset-status/`,
          params: {
            ...params,
          },
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({asset}) => ({type: 'AssetStatus' as const, id: (arg.eventUuid + asset.uuid)})),
            {type: 'AssetStatus', id: arg.eventUuid},
          ]
          : [{type: 'AssetStatus', id: arg.eventUuid}],
    }),
    getInvolvedAssetsRelatedOptionsForInfiniteScrolling: build.query<AssetsRelatedOptionGet, InvolvedAssetsRelatedOptionsFilterParams>({
      query: ({eventUuid, ...args}) => ({
        url: `/events/${eventUuid}/asset-status/related_options/`,
        params: args,
      }),
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    postEventAssetStatus: build.mutation<GetAssetStatusResponse, {
      eventUuid: string,
      text_note: string | null,
      assets: string[],
      state: ASSET_STATUS
    }>({
      query: ({eventUuid, ...params}) => ({
        url: `/events/${eventUuid}/asset-status/`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (res, error, arg) =>
        [
          ...arg.assets.map(a => ({type: 'AssetStatus' as const, id: arg.eventUuid + a})),
          {type: 'AssetStatus', id: arg.eventUuid},
          {type: 'EventConfiguration', id: arg.eventUuid},
        ],
    }),
    downloadEventAssetsReport: build.query<void, { columns?: string[], eventUuid: string }>({
      query: ({columns, eventUuid}) => {
        return {
          url: `/events/${eventUuid}/assets_download/`,
          params: {
            columns,
          },
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    getAllTheAssetsOfTheHistoryOfTheEventPaginated: build.query<GetAssetsOfTheHistoryOfTheEventResponse, GetAssetsOfTheHistoryOfTheEventParams>({
      query: ({eventUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/assets/`,
          params,
        };
      },
      providesTags: (res, error, arg) => [{type: 'EventConfiguration', id: 'LIST'}, {
        type: 'EventCommunications',
        id: arg.eventUuid,
      }],
    }),
    getAllTheClustersOfTheHistoryOfTheEventNotPaginated: build.query<GetClusterOfTheHistoryOfTheEvent[], {
      eventUuid: string
    }>({
      query: ({eventUuid}) => {
        return {
          url: `/events/${eventUuid}/clusters/`,
          params: {
            paginate: false,
          },
        };
      },
      providesTags: (res, error, arg) => [{type: 'EventConfiguration', id: 'LIST'}, {
        type: 'EventCommunications',
        id: arg.eventUuid,
      }],
    }),
    getEventCommunication: build.query<EventCommunication, { eventUuid: string, communicationUuid: string }>({
      query: ({eventUuid, communicationUuid}) => ({
        url: `/events/${eventUuid}/communications/${communicationUuid}/`,
      }),
      transformResponse: ({body, ...res}: EventCommunication) => ({
        ...res,
        body: sanitize(body),
      }),
    }),
    createCommunicationWithTextEditor: build.mutation<void, CreateCommunicationWithTextEditor>({
      query: ({event, body, ...args}) => ({
        url: `/events/${event}/communications/`,
        method: 'POST',
        body: {
          body: sanitize(body),
          ...args,
        },
      }),
      invalidatesTags: (res, error, arg) => [{type: 'EventCommunications', id: arg.event}],
    }),
    createCommunicationWithUpload: build.mutation<any, CreateCommunicationWithUpload>({
      query: ({event, ...params}) => {
        const postData = new FormData();
        for (const [key, value] of Object.entries(params)) {
          if (value !== undefined) {
            // @ts-ignore
            postData.append(key, value);
          }
        }
        return ({
          url: `/events/${event}/communications/`,
          method: 'POST',
          body: postData,
          formData: true,
        });
      },
      invalidatesTags: (res, error, arg) => [{type: 'EventCommunications', id: arg.event}],
    }),
    patchCommunication: build.mutation<EventCommunication, PatchCommunication>({
      query: ({eventUuid, communicationUuid, body, ...args}) => ({
        url: `/events/${eventUuid}/communications/${communicationUuid}/`,
        method: 'PATCH',
        body: {
          body: sanitize(body),
          ...args,
        },
      }),
      invalidatesTags: (res, error, arg) => [{type: 'EventCommunications', id: arg.eventUuid}],
    }),
    deleteEventCommunication: build.mutation<void, { eventUuid: string, communicationUuid: string }>({
      query: ({eventUuid, communicationUuid}) => ({
        url: `/events/${eventUuid}/communications/${communicationUuid}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, error, arg) => [{type: 'EventCommunications', id: arg.eventUuid}],
    }),
    getClusterCommunicationsPaginated: build.query<GetEventCommunicationResponse, GetEventClusterCommunicationsParams>({
      query: ({eventUuid, clusterUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/clusters/${clusterUuid}/communications/`,
          params: {
            ...params,
            ...!!params?.status?.length && {status: params.status.toString()},
          },
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({uuid}) => ({type: 'EventCommunications' as const, id: uuid})),
            {type: 'EventCommunications', id: arg.eventUuid},
          ]
          : [{type: 'EventCommunications', id: arg.eventUuid}],
    }),
    getAssetCommunicationsPaginated: build.query<GetEventCommunicationResponse, GetEventAssetCommunicationsParams>({
      query: ({eventUuid, assetUuid, ...params}) => {
        return {
          url: `/events/${eventUuid}/assets/${assetUuid}/communications/`,
          params: {
            ...params,
            ...!!params?.status?.length && {status: params.status.toString()},
          },
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({uuid}) => ({type: 'EventCommunications' as const, id: uuid})),
            {type: 'EventCommunications', id: arg.eventUuid},
          ]
          : [{type: 'EventCommunications', id: arg.eventUuid}],
    }),
    getEventTemplateMail: build.query<GetEventTemplateMail, { eventUuid: string, templateUuid: string }>({
      query: ({eventUuid, templateUuid}) => ({
        url: `/events/${eventUuid}/template-emails/${templateUuid}/`,
      }),
    }),
    downloadEventCommunication: build.query<void, { communicationUuid: string, eventUuid: string }>({
      query: ({communicationUuid, eventUuid}) => {
        return {
          url: `/events/${eventUuid}/communications/${communicationUuid}/download_email/`,
          params: {ext: 'eml'},
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    getEventsMapNotPaginated: build.query<GetEventsMap[], GetEventsParams>({
      query: (params) => {
        return {
          url: '/events-map/',
          params: {
            ...params,
            paginate: false,
          },
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res.flatMap(({uuid}) => [{type: 'EventConfiguration' as const, id: uuid}, {
              type: 'Events' as const,
              id: uuid,
            }]),
            {type: 'EventConfiguration', id: 'LIST'},
          ]
          :
          [{type: 'EventConfiguration', id: 'LIST'}],
    }),
    downloadEventHistory: build.query<void, { eventUuid: string }>({
      query: ({eventUuid}) => {
        return {
          url: `/events/${eventUuid}/download_history/`,
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    discardAllChanges: build.mutation<void, { eventUuid: string }>({
      query: ({eventUuid}) => {
        return {
          method: 'POST',
          url: `/events/${eventUuid}/dismiss_changes/`,
        };
      },
      invalidatesTags: (result, error, params) => [
        {type: 'EventConfiguration', id: params.eventUuid},
        {type: 'Events', id: params.eventUuid}],
    }),
  }),
});

export const {
  useGetEventsPaginatedQuery,
  useLazyGetEventsPaginatedQuery,
  useGetEventQuery,
  useLazyGetEventQuery,
  useCreateEventMutation,
  useGetEventsNotPaginatedQuery,
  useLazyGetEventsForInfiniteScrollingQuery,
  useGetEventsForSelectInfiniteScrollingQuery,
  useGetEventClusterTemplatesQuery,
  useLazyGetEventClustersPolygonsNotPaginatedQuery,
  useUpdateEventClustersPolygonsMutation,
  useSaveConfigurationMutation,
  usePatchEventMutation,
  useLazyGetEventClustersAssetsNotPaginatedQuery,
  useUpdateClusterAssetsMutation,
  useRemoveClusterMutation,
  useGetCheckAssetsChangesPaginatedQuery,
  useGetEventHistoryPaginatedQuery,
  useGetEventDetailHistoryQuery,
  useDeleteEventMutation,
  useGetEventAssetStatusPaginatedQuery,
  usePostEventAssetStatusMutation,
  useLazyDownloadEventAssetsReportQuery,
  useGetEventHistoryPerAssetPaginatedQuery,
  useGetAllTheAssetsOfTheHistoryOfTheEventPaginatedQuery,
  useGetAllTheClustersOfTheHistoryOfTheEventNotPaginatedQuery,
  useGetClusterCommunicationsPaginatedQuery,
  useGetAssetCommunicationsPaginatedQuery,
  useGetEventTemplateMailQuery,
  useCreateCommunicationWithTextEditorMutation,
  useCreateCommunicationWithUploadMutation,
  useLazyDownloadEventCommunicationQuery,
  useGetEventCommunicationQuery,
  useDeleteEventCommunicationMutation,
  usePatchCommunicationMutation,
  useGetEventsMapNotPaginatedQuery,
  useLazyDownloadEventHistoryQuery,
  useDiscardAllChangesMutation,
  useLazyGetInvolvedAssetsRelatedOptionsForInfiniteScrollingQuery,
} = eventsApi;