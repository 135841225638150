import {Pagination, PaginationProps, Table, TableProps} from 'antd';
import {Alert, GetAlertsParams} from '../../../../types/api/alerts';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  AlertTableColumn,
  AlertTableFilters,
  AlertTableSorters,
  DEFAULT_ALERT_TABLE_FILTERS,
  DEFAULT_ALERT_TABLE_SORTERS,
} from '../../../../types/internal/Alerts';
import {useNavigate} from 'react-router-dom';
import _, {capitalize} from 'lodash';
import {getTranslationAlertToManageTableColumnKey} from '../../../../utils/translation';
import {DocumentsTableColumnHeader} from '../../../documents/TablesViews/DocumentsTableColumnHeader';
import TableSorter from '../../../common/TableSorter';
import SearchFilter from '../../../common/filters/SearchFilter';
import {useTranslation} from 'react-i18next';
import {PaginationConfig} from 'antd/lib/pagination';
import NumberRangeFilter from '../../../common/filters/NumberRangeFilter';
import IsForecastFilter from '../../../alerts/table/filters/IsForecastFilter';
import DateRangeFilter from '../../../common/filters/DateRangeFilter';
import DateTimeFormat from '../../../../locales/DateTimeFormat';
import dayjs, {Dayjs} from 'dayjs';
import {fromFeToBeAlertsFilter, fromFeToBeSorter} from '../../../../utils/parser';
import ConnectAlertToEventButton from '../../../alerts/ConnectAlertToEventButton';
import {GetEvent} from '../../../../types/api/getEventsResult';
import {ColumnsType} from "antd/lib/table";

export default function AlertsEventTable({getData, selectedColumns, tableProps, paginationProps, event, selectedAlerts, setSelectedAlerts}: {
  getData: (params: GetAlertsParams) => void,
  selectedColumns?: string[],
  tableProps?: TableProps<Alert>,
  paginationProps?: PaginationProps,
  event?: GetEvent,
  selectedAlerts?: React.Key[],
  setSelectedAlerts?: React.Dispatch<React.SetStateAction<React.Key[]>>,
}) {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const resetAlertSelection = () => {
    if (setSelectedAlerts) {
      setSelectedAlerts([]);
    }
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (setSelectedAlerts) {
      setSelectedAlerts(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedAlerts,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  const [filters, setFilters] = useState<AlertTableFilters>(DEFAULT_ALERT_TABLE_FILTERS);
  const [sorters, setSorters] = useState<AlertTableSorters>(DEFAULT_ALERT_TABLE_SORTERS);
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => {
      setPagination(prevPag => ({...prevPag, current: page, pageSize}))
      resetAlertSelection()
    },
    style: {textAlign: 'center', marginTop: '40px'},
    ...paginationProps,
  });

  const getDefaultProps = useCallback((column: AlertTableColumn) => ({
    title: capitalize(t(getTranslationAlertToManageTableColumnKey(column))),
    key: column,
    dataIndex: column,
    width: 200,
  }), [t]);

  const getDates = (dates: [a: string, b: string] | null): [Dayjs, Dayjs] | null => {
    let start: Dayjs | null = null;
    let end: Dayjs | null = null;
    if (dates) {
      start = dayjs(dates[0]);
      end = dayjs(dates[1]);
      return [start, end];
    } else {
      return null;
    }
  };

  const startFilters = getDates(filters.start_datetime);
  const endFilters = getDates(filters.end_datetime);
  const antdColumns: ColumnsType<Alert> = useMemo(() => [
      {
        ...getDefaultProps('title'),
        title: <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAlertToManageTableColumnKey('title')))}
          sorter={<TableSorter
            value={sorters.title}
            onChange={type => setSorters(prevState => ({...prevState, title: type}))}
          />}
          filter={
            <SearchFilter
              onChange={value => setFilters(prevState => ({...prevState, title: value}))}
              placeholder={capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey('title'))}))}
              selectedFilter={filters.title || undefined}
            />
          }
          onFilterReset={() => setFilters(prevState => ({...prevState, title: null}))}
          isFilterDisabled={_.isEmpty(filters.title)}
        />,
      },
      {
        ...getDefaultProps('is_forecast'),
        title: <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAlertToManageTableColumnKey('is_forecast')))}
          sorter={<TableSorter
            value={sorters['is_forecast']}
            onChange={type => setSorters(prevState => ({...prevState, is_forecast: type}))}
          />}
          filter={<IsForecastFilter
            activeFilters={filters['is_forecast'] || undefined}
            handleFilterChange={(values) => setFilters(prevState => ({...prevState, is_forecast: values}))}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, is_forecast: null}))}
          isFilterDisabled={_.isEmpty(filters.is_forecast)}
        />,
        render: (v: boolean) => {
          return capitalize(t(v ? 'alerts.isForecast' : 'alerts.isNotForecast'));
        },
      },
      {
        ...getDefaultProps('assets_count'),
        title: <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAlertToManageTableColumnKey('assets_count')))}
          sorter={<TableSorter
            value={sorters.assets_count}
            onChange={type => setSorters(prevState => ({...prevState, assets_count: type}))}
          />}
          filter={<NumberRangeFilter
            onChange={value => setFilters(prevState => ({...prevState, assets_count: value}))}
            min={0}
            max={10000}
            step={10}
            selectedFilters={filters['assets_count']}
            formatValue={value => `${value.toString()}`}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, assets_count: null}))}
          isFilterDisabled={_.isEmpty(filters.assets_count)}
        />,
      },
      {
        ...getDefaultProps('source_url'),
        title: <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAlertToManageTableColumnKey('source_url')))}
          sorter={<TableSorter
            value={sorters['source_url']}
            onChange={type => setSorters(prevState => ({...prevState, source_url: type}))}
          />}
          filter={
            <SearchFilter
              onChange={value => setFilters(prevState => ({...prevState, source_url: value}))}
              placeholder={capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey('source_url'))}))}
              selectedFilter={filters['source_url'] || undefined}
            />
          }
          onFilterReset={() => setFilters(prevState => ({...prevState, source_url: null}))}
          isFilterDisabled={_.isEmpty(filters.source_url)}
        />,
      },
      {
        ...getDefaultProps('start_datetime'),
        title: <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAlertToManageTableColumnKey('start_datetime')))}
          sorter={<TableSorter
            value={sorters['start_datetime']}
            onChange={type => setSorters(prevState => ({...prevState, start_datetime: type}))}
          />}

          filter={<DateRangeFilter
            selectedFilters={startFilters}
            onChange={value => setFilters(prevState => ({
              ...prevState,
              start_datetime: [
                value[0].startOf('day').toISOString(),
                value[1].endOf('day').toISOString()
              ],
            }))}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, start_datetime: null}))}
          isFilterDisabled={_.isEmpty(filters['start_datetime'])}
        />,
        render: (v: string | null) => {
          if (v) {
            return <DateTimeFormat>{v}</DateTimeFormat>;
          }
        },
      },
      {
        ...getDefaultProps('end_datetime'),
        title: <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAlertToManageTableColumnKey('end_datetime')))}
          sorter={<TableSorter
            value={sorters['end_datetime']}
            onChange={type => setSorters(prevState => ({...prevState, end_datetime: type}))}
          />}

          filter={<DateRangeFilter
            selectedFilters={endFilters}
            onChange={value => setFilters(prevState => ({
              ...prevState,
              end_datetime: [
                value[0].startOf('day').toISOString(),
                value[1].endOf('day').toISOString()
              ],
            }))}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, end_datetime: null}))}
          isFilterDisabled={_.isEmpty(filters['end_datetime'])}
        />,
        render: (v: string | null) => {
          if (v) {
            return <DateTimeFormat>{v}</DateTimeFormat>;
          }
        },
      },
      ...event ? [{
        key: 'actions',
        dataIndex: 'uuid',
        render: (value: string, record: Alert) => {
          if (event) {
            return <div style={{textAlign: 'right'}}>
              <ConnectAlertToEventButton
                eventUuid={event.uuid}
                selectedAlerts={[value]}
              />
            </div>;
          }
        },
      }] : [],
    ]
      .filter(el => selectedColumns ? selectedColumns.some(col => col === el.key) : true),
    [endFilters, filters, getDefaultProps, event, selectedColumns, sorters, startFilters, t]);

  useEffect
  (() => {
    getData({
      ...fromFeToBeAlertsFilter(filters),
      page: pagination.current,
      page_size: pagination.pageSize,
      ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
        ordering: fromFeToBeSorter(sorters),
      } : {}),
    });
  }, [filters, getData, pagination, sorters]);

  return <>
    <Table
      <Alert>
      scroll={{x: true}}
      rowKey={'uuid'}
      columns={antdColumns}
      pagination={false}
      {...!!(setSelectedAlerts && selectedAlerts) && {rowSelection}}
      onRow={(record) => (
        {
          onClick: (e) => {
            navigate(`/alerts/${record.uuid}`);
          },
          style: {
            cursor: 'pointer',
          },
        })
      }
      {...tableProps}
    />
    <Pagination
      {...pagination}
      {...paginationProps}
    />
  </>;
}