import {Pagination, Table} from 'antd';
import React, {useState} from 'react';
import {ColumnsType} from 'antd/lib/table';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {GetEvent, REDUCED_STATES} from '../../types/api/getEventsResult';
import {useGetEventsPaginatedQuery} from '../../redux/api/events';
import {PaginationConfig} from 'antd/lib/pagination';
import DateTimeFormat from '../../locales/DateTimeFormat';
import ConnectAlertToEventButton from './ConnectAlertToEventButton';
import ConnectAlertGroupToEvent from "./suggestion/ConnectAlertGroupToEvent";

export default function ConnectAlertsToEvent({onFinish, evenTypeUuid, ...props}: {
    alerts: React.Key[],
    onFinish?: () => void,
    evenTypeUuid?: string,
  }
  | {
  alertGroup: string,
  onFinish?: () => void,
  evenTypeUuid?: string,
}) {

  const {t} = useTranslation();
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'right'},
  });
  const {data: events, isFetching} = useGetEventsPaginatedQuery({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...!!evenTypeUuid && {event_type: evenTypeUuid}
  });

  const columns: ColumnsType<GetEvent> = [
    {
      title: capitalize(t('events.event', {count: 1})),
      render: (value, record) => {
        return `${record.name} - ${record.code} `;
      },
    },
    {
      title: capitalize(t('events.status')),
      key: 'reduced_state',
      dataIndex: 'reduced_state',
      render: (value, record) => {
        switch (record.reduced_state) {
          case REDUCED_STATES.active:
            return capitalize(t('events.active'))
          case REDUCED_STATES.archived:
            return capitalize(t('events.archived'))
        }
      }
    },
    {
      title: capitalize(t('events.startDateEvent')),
      key: 'start_datetime',
      dataIndex: 'start_datetime',
      render: value => <DateTimeFormat>{value}</DateTimeFormat>,
    },
    {
      key: 'actions',
      render: (value, record) => {
        return <div style={{textAlign: 'right'}}>
          {'alerts' in props && <ConnectAlertToEventButton
            eventUuid={record.uuid}
            selectedAlerts={props.alerts}
            afterConnect={() => {
              if (onFinish)
                onFinish();
            }}
          />}
          {'alertGroup' in props && <ConnectAlertGroupToEvent
            alertGroupUuid={props.alertGroup}
            eventUuid={record.uuid}
            afterConnect={() => {
              if (onFinish)
                onFinish();
            }}
          />
          }
        </div>;
      },
    },
  ];

  return <>
    <Table
      <GetEvent>
      columns={columns}
      scroll={{x: true}}
      pagination={false}
      dataSource={events?.results}
      rowKey={'uuid'}
      loading={isFetching}
    />
    <Pagination
      disabled={isFetching}
      showSizeChanger
      style={{textAlign: 'right'}}
    />
  </>;
}