import {useEventContext} from "../../../context/event/EventContext";
import {Input, Typography} from "antd";
import {capitalize, debounce} from "lodash";
import {useTranslation} from "react-i18next";
import {usePatchEventMutation} from "../../../redux/api/events";
import {useEffect} from "react";
import {useMessageContext} from "../../../context/message/MessageContext";
import {isEventEdit} from "../../Authentication/permissions";
import {useGetUserProfileQuery} from "../../../redux/api/locales";
import {REDUCED_STATES} from "../../../types/api/getEventsResult";


export default function EventNotes() {

  const {event} = useEventContext();
  const {t} = useTranslation()
  const [updateEvent, {isSuccess, isError}] = usePatchEventMutation()
   const debounceUpdate = debounce((v: string) => {
     if (event?.uuid) {
       updateEvent({notes: v, uuid: event.uuid})
     }
  }, 1000);
  const setMessage = useMessageContext();


  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))})
    }
  }, [isSuccess, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.editFailMessage'))})
    }
  }, [isError, setMessage, t]);

  const {data: userData,} = useGetUserProfileQuery();
  const canEdit = !!userData && isEventEdit(userData.type) && event?.reduced_state !== REDUCED_STATES.archived


  return <>
    {canEdit ? <Input.TextArea
      key={event?.uuid}
      defaultValue={event?.notes ?? ""}
      placeholder={capitalize(t('events.notesPlaceholder'))}
      autoSize={{
        minRows: 10
      }}
      onChange={e => debounceUpdate(e.target.value)}
    />
      :
      <Typography.Paragraph>{event?.notes?.trim() || capitalize(t("events.noNotesProvided"))}</Typography.Paragraph>

    }
  </>
}