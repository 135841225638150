import {Card, Col, Divider, Row, Space, Tag, Typography} from 'antd';
import {LabeledValue} from '../../common/LabeledValue';
import {capitalize} from 'lodash';
import {useEventContext} from '../../../context/event/EventContext';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {useTranslation} from 'react-i18next';
import {EventNotHappenedModal} from './EventNotHappenedModal';
import {EVENT_STATES} from "../../../types/api/getEventsResult";
import {usePatchEventMutation} from "../../../redux/api/events";
import EditDateModal from "./EditDateModal";
import dayjs, {isDayjs} from "dayjs";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import GrecimEdit from "../GrecimEdit";
import DeleteHappenedDate from "./DeleteHappenedDate";
import EventHappenedModal from "./EventHappenedModal";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isEventArchive, isEventEdit} from "../../Authentication/permissions";
import InvolvedAssetsTable from "../involvedAssets/InvolvedAssetsTable";
import DateTimeModal from "./DateTimeModal";

const {Title} = Typography;
export const EventStatusCard = () => {

  const {t} = useTranslation();

  const {event} = useEventContext();
  const {
    data: userData,
  } = useGetUserProfileQuery();
  const [updateEvent] = usePatchEventMutation()

  return (
    <Card style={{flexGrow: 1}}>
      <Row gutter={16} align={'middle'}>
        <Col>
          <Title level={4} style={{marginTop: 0}}>
            {capitalize(t('events.state'))}
          </Title>
        </Col>
        <Col>{(event?.state === EVENT_STATES["Not Happened"]) &&
          <Tag>{capitalize(t('events.eventNotOccurred'))}</Tag>}</Col>
      </Row>

      <Row style={{marginTop: '1.5rem'}} align={'top'} wrap={false} gutter={16}>

        {!!event?.forecast_start_datetime && <Col xs={12}>
          <Space>
            <LabeledValue
              label={capitalize(t('events.eventScheduledFor'))}
              value={event?.forecast_start_datetime && userData?.language ? dateToLocaleString(event.forecast_start_datetime, userData.language, 'HH:mm') : VALUE_NOT_PRESENT}
            />

            {event?.state === EVENT_STATES.Forecast && <PermissionRoute isAuthorized={isEventEdit}>
              <EditDateModal
                modal={{
                  title: capitalize(t('actions.editItem', {item: t('events.dateStartForecast')}))
                }}
                onFinish={v => updateEvent({uuid: event?.uuid, forecast_start_datetime: v})}
              />
            </PermissionRoute>}
          </Space>
        </Col>}

        {(event?.state === EVENT_STATES.Happened || event?.state === EVENT_STATES.Closed) &&
          <Col xs={12}>
            <Space>
              <LabeledValue
                label={capitalize(t('events.eventOccurredFor'))}
                value={event?.start_datetime && userData?.language ? dateToLocaleString(event.start_datetime, userData.language, 'HH:mm') : VALUE_NOT_PRESENT}
              />
              {(event?.state === EVENT_STATES.Happened) && <PermissionRoute isAuthorized={isEventEdit}>
                <DateTimeModal
                  modal={{
                    title: capitalize(t('actions.editItem', {item: t('events.dateStartEvent')}))
                  }}
                  datePickerProps={{
                    disabledDate: current => {
                      return current && current > dayjs().endOf('day')
                    },
                    showTime: true,
                  }}
                  formItemProps={{
                    label: capitalize(t('events.dateStartEvent')),
                    rules: [
                      {
                        required: true,
                        message: capitalize(t('form.requiredField', {field: t('misc.date')})),
                        validator: (_, value) => {
                          if (!value || !isDayjs(value)) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        message: capitalize(t('form.notBeforeNowError')),
                        validator: (_, value) => {
                          let isOk = true
                          if (isDayjs(value)) {
                            isOk = dayjs() > value
                          }
                          return isOk ? Promise.resolve() : Promise.reject();
                        },
                      },
                    ]
                  }}
                  onFinish={v => updateEvent({uuid: event?.uuid, start_datetime: v})}
                />
                <DeleteHappenedDate onConfirm={() => updateEvent({uuid: event?.uuid, state: EVENT_STATES.Forecast})}/>
              </PermissionRoute>}
            </Space>
          </Col>}
      </Row>

      <Row gutter={[16, 16]}>
        {
          event?.state === EVENT_STATES["Forecast"] && (
            <PermissionRoute isAuthorized={isEventEdit}>
              <Col>
                <DateTimeModal
                  modal={{
                    title: capitalize(t('events.eventOccurred'))
                  }}
                  description={<Typography.Text strong={true}>{capitalize(t('events.selectWhenEventOccurred'))}</Typography.Text>}
                  onFinish={v => {
                    updateEvent({
                      uuid: event?.uuid,
                      start_datetime: v,
                      state: EVENT_STATES.Happened,
                    })
                  }
                  }
                  modalButton={{
                    type: 'default',
                    icon: null,
                    children: capitalize(t('events.eventOccurred'))
                  }}
                  datePickerProps={{
                    disabledDate: current => {
                      return current && current > dayjs().endOf('day')
                    },
                    showTime: true,
                  }}
                  formItemProps={{
                    label: capitalize(t('events.dateStartEvent')),
                    rules: [
                      {
                        required: true,
                        message: capitalize(t('form.requiredField', {field: t('misc.date')})),
                        validator: (_, value) => {
                          if (!value || !isDayjs(value)) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        message: capitalize(t('form.notBeforeNowError')),
                        validator: (_, value) => {
                          let isOk = true
                          if (isDayjs(value)) {
                            isOk = dayjs() > value
                          }
                          return isOk ? Promise.resolve() : Promise.reject();
                        },
                      },
                    ]
                  }}
                />
              </Col>
              <Col>
                <EventNotHappenedModal/>
              </Col>
            </PermissionRoute>)
        }
        <PermissionRoute isAuthorized={isEventArchive}>{
          event?.state === EVENT_STATES["Happened"] && (
            <Col>
              <EventHappenedModal/>
            </Col>)
        }
        </PermissionRoute>
      </Row>

      <Divider style={{marginTop: '1rem'}}/>
      <GrecimEdit/>
      <InvolvedAssetsTable/>
    </Card>
  );
};