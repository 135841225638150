import {Button, Col, Modal, Row, Typography} from "antd";
import {FileTextOutlined} from "@ant-design/icons";
import React, {useCallback, useRef, useState} from "react";
import TextArea, {TextAreaRef} from "antd/lib/input/TextArea";
import {Trans, useTranslation} from "react-i18next";
import {capitalize} from "lodash";
import {usePatchNoteMutation} from "../../redux/api/notes";
import {useMessageContext} from "../../context/message/MessageContext";
import {EventAssetNoteType} from "../../types/api/getEventsResult";
import {useEventContext} from "../../context/event/EventContext";

export default function EventAssetNote({uuid, text, assets_count}: EventAssetNoteType) {

  const [isOpen, setIsOpen] = useState(false)
  const {t} = useTranslation()
  const ref = useRef<TextAreaRef | null>(null)
  const [updateNote, {isLoading}] = usePatchNoteMutation()
  const setMessage = useMessageContext()
  const {event} = useEventContext()

  const handleSubmit = useCallback(async () => {
    try {
      if (ref) {
        const updatedText = ref.current?.resizableTextArea?.textArea.value || ""
        if (updatedText !== text) {
          await updateNote({uuid, text: updatedText, eventUuid: event?.uuid}).unwrap()
        }
        setMessage({success: capitalize(t('form.editSuccessMessage'))})
        setIsOpen(false)
      }
    } catch {
      setMessage({error: capitalize(t('form.editFailMessage'))})
    }

  }, [text, setMessage, t, updateNote, uuid, event?.uuid])


  return <>
    <Modal
      centered
      footer={<Row justify={'end'}>
        <Col>
          <Button
            type={'primary'}
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSubmit}
          >
            {capitalize(t('actions.edit'))}</Button>
        </Col>
      </Row>}
      destroyOnClose={true}
      title={capitalize(t('actions.editItem', {item: t('misc.note')}))}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      {assets_count > 1 && <Trans
        i18nKey={'events.assetNoteDisclaimer'}
        values={{
          number: assets_count,
          item: t('assets.asset', {count: assets_count}),
        }}
        components={{p: <Typography.Paragraph italic/>, bold: <Typography.Text strong/>}}
      />}
      <TextArea
        ref={ref}
        key={uuid}
        defaultValue={text}
        autoSize
      />

    </Modal>
    <Button
      onClick={() => setIsOpen(true)}
      icon={<FileTextOutlined/>}
      type={'text'}
    />
  </>
}