import {useGetEventsPaginatedQuery} from '../../../redux/api/events';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {ColumnsType} from 'antd/lib/table';
import {GetEvent, REDUCED_STATES} from '../../../types/api/getEventsResult';
import _, {capitalize, isEmpty} from 'lodash';
import DateTimeFormat from '../../../locales/DateTimeFormat';
import {Button, Col, Pagination, Row, Table, Tag} from 'antd';
import {useGetEventTypesNotPaginatedQuery} from '../../../redux/api/eventTypes';
import {CustomLoading} from '../../common/CustomLoading';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  addArchivedEventsTableFilters,
  addArchivedEventsTableSorters,
  removeArchivedEventsTableFilters,
  removeArchivedEventsTableSorters,
  selectArchivedEventsTableFilters,
  selectArchivedEventsTablePagination,
  selectArchivedEventsTableSorters,
  setArchivedEventsTablePagination,
} from '../../../redux/slices/events';
import {DocumentsTableColumnHeader} from '../../documents/TablesViews/DocumentsTableColumnHeader';
import TableSorter from '../../common/TableSorter';
import {fromFeToBeSorter} from '../../../utils/parser';
import EventCodeFilter from './filters/EventCodeFilter';
import {fromFeToBeEventsFilters} from '../../../types/internal/tables/archivedEventsTable';
import EventNameFilter from './filters/EventNameFilter';
import DatesFilter from '../../common/filters/DatesFilter';
import EventTypeFilter from '../../alerts/table/filters/EventTypeFilter';
import ResetArchivedEventsTableFiltersAndSorters from './filters/ResetArchivedEventsTableSortersAndFilters';
import {DownloadOutlined} from '@ant-design/icons';
import DeleteEventButton from "../DeleteEventButton";

export default function ArchivedEvents() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {data: eventTypes, isFetching: isFetchingEventTypes} = useGetEventTypesNotPaginatedQuery();
  const filters = useSelector(selectArchivedEventsTableFilters);
  const sorters = useSelector(selectArchivedEventsTableSorters);
  const pagination = useSelector(selectArchivedEventsTablePagination);
  const {data: events, isFetching, isUninitialized} = useGetEventsPaginatedQuery({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...!isEmpty(sorters) && {ordering: fromFeToBeSorter(sorters)},
    ...fromFeToBeEventsFilters(filters),
    reduced_state: REDUCED_STATES.archived,
  }, {refetchOnMountOrArgChange: true});

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const selectedEvents: GetEvent[] = events?.results.filter(event => selectedRowKeys.includes(event.uuid)) || [];
  const resetEventsSelection = () => {
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    return () => {
      resetEventsSelection();
    };
  }, []);

  const columns: ColumnsType<GetEvent> = [
    {
      title: <DocumentsTableColumnHeader
        title={capitalize(t('events.code', {count: 1}))}
        sorter={<TableSorter
          value={sorters['code'] || null}
          onChange={type => {
            if (type) {
              dispatch(addArchivedEventsTableSorters({code: type}));
            } else {
              dispatch(removeArchivedEventsTableSorters('code'));
            }
          }}
        />}
        filter={<EventCodeFilter
          activeFilters={filters.code}
          handleFilterChange={(values) => {
            if (values.length) {
              dispatch(addArchivedEventsTableFilters({code: values}));
            } else {
              dispatch(removeArchivedEventsTableFilters('code'));
            }
          }}
          params={{
            reduced_state: REDUCED_STATES.archived,
          }}

        />}
        onFilterReset={() => dispatch(removeArchivedEventsTableFilters('code'))}
        isFilterDisabled={_.isEmpty(filters['code'])}
      />,
      key: 'code',
      dataIndex: 'code',
      render: (value) => {
        return `#${value}`;
      },
    },
    {
      title: <DocumentsTableColumnHeader
        title={capitalize(t('events.name', {count: 1}))}
        sorter={<TableSorter
          value={sorters['name'] || null}
          onChange={type => {
            if (type) {
              dispatch(addArchivedEventsTableSorters({name: type}));
            } else {
              dispatch(removeArchivedEventsTableSorters('name'));
            }
          }}
        />}
        filter={<EventNameFilter
          activeFilters={filters.name}
          handleFilterChange={(values) => {
            if (values.length) {
              dispatch(addArchivedEventsTableFilters({name: values}));
            } else {
              dispatch(removeArchivedEventsTableFilters('name'));
            }
          }}
          params={{
            reduced_state: REDUCED_STATES.archived,
          }}

        />}
        onFilterReset={() => dispatch(removeArchivedEventsTableFilters('name'))}
        isFilterDisabled={_.isEmpty(filters['name'])}
      />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: capitalize(t('events.status')),
      key: 'reduced_state',
      dataIndex: 'reduced_state',
      render: (value, record) => {
        switch (record.reduced_state) {
          case REDUCED_STATES.active:
            return capitalize(t('events.active'))
          case REDUCED_STATES.archived:
            return capitalize(t('events.archived'))
        }
      }
    },
    {
      title: <DocumentsTableColumnHeader
        title={capitalize(t('events.startDateEvent'))}
        sorter={<TableSorter
          value={sorters['start_datetime'] || null}
          onChange={type => {
            if (type) {
              dispatch(addArchivedEventsTableSorters({start_datetime: type}));
            } else {
              dispatch(removeArchivedEventsTableSorters('start_datetime'));
            }
          }}
        />}
        filter={<DatesFilter
          isNull={filters.is_start_datetime_null}
          start={filters.start_datetime_after}
          end={filters.start_datetime_before}
          onNullChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`is_start_datetime_null`]: value}));
              dispatch(removeArchivedEventsTableFilters([`start_datetime_after`, `start_datetime_before`]));
            } else {
              dispatch(removeArchivedEventsTableFilters(`is_start_datetime_null`));
            }
          }}
          onStartChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`start_datetime_after`]: value}));
            } else {
              dispatch(removeArchivedEventsTableFilters(`start_datetime_after`));
            }
          }}
          onEndChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`start_datetime_before`]: value}));
            } else {
              dispatch(removeArchivedEventsTableFilters(`start_datetime_before`));
            }
          }}
        />}
        onFilterReset={() => dispatch(removeArchivedEventsTableFilters(['is_start_datetime_null', 'start_datetime_before', 'start_datetime_after']))}
        isFilterDisabled={!(filters['is_start_datetime_null'] || filters['start_datetime_before'] || filters['start_datetime_after'])}
      />,
      key: 'start_datetime',
      dataIndex: 'start_datetime',
      render: value => <DateTimeFormat>{value}</DateTimeFormat>,
    },
    {
      title: <DocumentsTableColumnHeader
        title={capitalize(t('events.storageDate'))}
        sorter={<TableSorter
          value={sorters['end_datetime'] || null}
          onChange={type => {
            if (type) {
              dispatch(addArchivedEventsTableSorters({end_datetime: type}));
            } else {
              dispatch(removeArchivedEventsTableSorters('end_datetime'));
            }
          }}
        />}
        filter={<DatesFilter
          isNull={filters.is_end_datetime_null}
          start={filters.end_datetime_after}
          end={filters.end_datetime_before}
          onNullChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`is_end_datetime_null`]: value}));
              dispatch(removeArchivedEventsTableFilters([`end_datetime_after`, `end_datetime_before`]));
            } else {
              dispatch(removeArchivedEventsTableFilters(`is_end_datetime_null`));
            }
          }}
          onStartChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`end_datetime_after`]: value}));
            } else {
              dispatch(removeArchivedEventsTableFilters(`end_datetime_after`));
            }
          }}
          onEndChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`end_datetime_before`]: value}));
            } else {
              dispatch(removeArchivedEventsTableFilters(`end_datetime_before`));
            }
          }}
        />}
        onFilterReset={() => dispatch(removeArchivedEventsTableFilters(['is_end_datetime_null', 'end_datetime_before', 'end_datetime_after']))}
        isFilterDisabled={!(filters['is_end_datetime_null'] || filters['end_datetime_before'] || filters['end_datetime_after'])}
      />,
      key: 'end_datetime',
      dataIndex: 'end_datetime',
      render: value => <DateTimeFormat>{value}</DateTimeFormat>,
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('events.eventType', {count: 1}))}
          sorter={<TableSorter
            value={sorters.event_type || null}
            onChange={type => {
              if (type) {
                dispatch(addArchivedEventsTableSorters({event_type: type}));
              } else {
                dispatch(removeArchivedEventsTableSorters('event_type'));
              }
            }}
          />}
          filter={<EventTypeFilter
            activeFilters={filters.event_type || undefined}
            handleFilterChange={(values) => {
              if (values.length) {
                dispatch(addArchivedEventsTableFilters({event_type: values}));
              } else {
                dispatch(removeArchivedEventsTableFilters('event_type'));
              }
            }}
          />}
          onFilterReset={() => dispatch(removeArchivedEventsTableFilters('event_type'))}
          isFilterDisabled={!filters['event_type']}
        />
      ),
      key: 'event_type',
      dataIndex: 'event_type',
      render: value => {
        const eventType = eventTypes?.find(ev => ev.uuid === value);
        if (eventType) {
          return <Tag>{eventType.name}</Tag>;
        }
      },
    },
    /*{
      title: <DocumentsTableColumnHeader
        title={capitalize(t('clusters.cluster', {count: 2}))}
        sorter={<TableSorter
          value={sorters.clusters || null}
          onChange={type => {
            if (type) {
              dispatch(addArchivedEventsTableSorters({clusters: type}));
            } else {
              dispatch(removeArchivedEventsTableSorters('clusters'));
            }
          }}
        />}
        filter={<NumbersFilter
          onNullChange={(v) => {
            if (v) {
              dispatch(addArchivedEventsTableFilters({[`is_clusters_null`]: v}));
              dispatch(removeArchivedEventsTableFilters([`clusters_min`, `clusters_max`]));
            } else {
              dispatch(removeArchivedEventsTableFilters(`is_clusters_null`));
            }
          }}
          onMinChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`clusters_min`]: value}));
            } else {
              dispatch(removeArchivedEventsTableFilters(`clusters_min`));
            }
          }}
          onMaxChange={(value) => {
            if (value) {
              dispatch(addArchivedEventsTableFilters({[`clusters_max`]: value}));
            } else {
              dispatch(removeArchivedEventsTableFilters(`clusters_max`));
            }
          }}
          min={filters[`clusters_min`]}
          max={filters[`clusters_max`]}
          isNull={filters[`is_clusters_null`]}
        />}
        onFilterReset={() => dispatch(removeArchivedEventsTableFilters(['is_clusters_null', 'clusters_min', 'clusters_max']))}
        isFilterDisabled={!(filters['is_clusters_null'] || filters['clusters_max'] || filters['clusters_min'])}
      />,
      key: 'clusters',
      dataIndex: 'clusters',
    },*/
  ];

  return <>
    <Row gutter={[16, 16]} align={'middle'}>
      <Col>
        <ResetArchivedEventsTableFiltersAndSorters/>
      </Col>
      <Col>
        <Button
          icon={<DownloadOutlined/>}
          // todo download report
          disabled
          // disabled={!hasSelected}
          onClick={() => {
            console.log(selectedEvents);
          }}
        >
          {capitalize(t('assets.downloadReport'))}
        </Button>
      </Col>
      {hasSelected && <Col>
        <DeleteEventButton
          selectedEvents={selectedRowKeys.map(el => el.toString())}
          afterDelete={resetEventsSelection}
        />
      </Col>}
    </Row>
    <Table
      <GetEvent>
      columns={columns}
      rowSelection={rowSelection}
      scroll={{x: true}}
      pagination={false}
      dataSource={events?.results}
      rowKey={'uuid'}
      loading={
        {
          spinning: isFetching || isUninitialized || isFetchingEventTypes,
          indicator: <CustomLoading/>,
        }
      }
      onRow={(record) => (
        {
          onClick: (e) => {
            navigate(`${record.uuid}`);
          },
          style: {
            cursor: 'pointer',
          },
        })
      }
    />
    <Pagination
      disabled={!events?.count}
      showSizeChanger={true}
      total={events?.count}
      showTotal={(total, range) => t('table.pageSizeOfTotal', {
        rangeStart: range[0],
        rangeEnd: range[1],
        total,
      })}
      onChange={(current, pageSize) => {
        dispatch(setArchivedEventsTablePagination({current, pageSize}));
      }}
      style={{textAlign: 'center'}}
      {...pagination}
    />
  </>;
}