import {useTranslation} from "react-i18next";
import {useEventContext} from "../../../context/event/EventContext";
import {ReactNode, useState} from "react";
import DateTimeModal from "./DateTimeModal";
import {capitalize} from "lodash";
import {ButtonProps} from "antd";
import dayjs, {isDayjs} from "dayjs";

export const CloseEventModal = ({modalTitle, modalDescription, dateLabel, modalButton, onFinish}: {modalTitle: string, modalDescription: ReactNode, dateLabel: string, modalButton: ButtonProps, onFinish: (date: string) => void}) => {

  const {t} = useTranslation();
  const {event} = useEventContext();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  return (<>
    <DateTimeModal
      onFinishButton={{
        danger: true,
        children: capitalize(t('events.closeAndArchive'))
      }}
      modal={{
        open: isModalOpen,
        title: modalTitle,
        onCancel: () => setIsModalOpen(false)
      }}
      description={modalDescription}

      datePickerProps={{
        disabledDate: current => {
          const isAfterNow = current > dayjs()
          const isBeforeStartDateTime = !!(event?.start_datetime && current < dayjs(event?.start_datetime))
          const isBeforeForecastDateTime = !!(!event?.start_datetime && event?.forecast_start_datetime && current < dayjs(event?.forecast_start_datetime))
          return isAfterNow || isBeforeStartDateTime || isBeforeForecastDateTime
        },
        showTime: true,
      }}
      initialValue={dayjs()}
      formItemProps={{
        label: dateLabel,
        rules: [
          {
            required: true,
            message: capitalize(t('form.requiredField', {field: t('misc.date')})),
            validator: (_, value) => {
              if (!value || !isDayjs(value)) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
          },
          {
            message: capitalize(t('form.notBeforeNowError')),
            validator: (_, value) => {
              let isOk = true
              if (isDayjs(value)) {
                isOk = dayjs() > value
              }
              return isOk ? Promise.resolve() : Promise.reject();
            },
          },
        ]
      }}
      onFinish={v => {
        setIsModalOpen(false)
        onFinish(v)
      }}
      modalButton={{
        ...modalButton,
        onClick: () => setIsModalOpen(true)
    }}
    />
  </>);
};