import {GenericPaginatedApi, GenericPaginatedParamsApi} from './paginatedApi';
import {NewMultiPolygonGeometry} from '../../redux/api/geoshapes';

export enum EVENT_STATES {
  Forecast = 'FC',
  Happened = 'HP',
  Closed = 'CL',
  'Not Happened' = 'NH'
}

export enum REDUCED_STATES {
  active = 'AC',
  archived = 'AR'
}

export enum PHASE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  LIVE = 'LIVE',
}

export enum CHANGES {
  ONLY = 'ONLY',
  WITH = 'WITH',
  WITHOUT = 'WITHOUT',
}

export enum ASSET_STATUS {
  OPEN = 'O',
  CLOSED = 'C',
  REMOTE_WORK = 'R',
  CLOSED_REMOTE_WORK = 'RC'
}

export interface AssetMarker {
  'uuid': string,
  'position': { lat: number, lng: number }
}

export type AssetAddress = {
  'uuid': string,
  'sigi_code': string,
  'country': string,
  'admin_level_1': string,
  'admin_level_2': string,
  'admin_level_3': string,
  'address': string
}

export type GetEvent = {
  'alert_source': string[] | null,
  'ancestor_event': string | null,
  'changes': CHANGES,
  'code': string,
  'color_code': string,
  'description': string | null,
  'end_datetime': string | null,
  'epicenter': { lat: number, lng: number } | null,
  'event_type': string,
  'forecast_start_datetime': string | null,
  'involved_assets': number,
  'is_special_management': boolean,
  'last_update': string,
  'name': string,
  'notes': string | null,
  'phase': PHASE,
  'reduced_state': REDUCED_STATES,
  'start_datetime': string | null,
  'state': EVENT_STATES,
  'uuid': string,
}

export type GetEvents = GenericPaginatedApi<GetEvent>

export interface GetEventsParams extends GenericPaginatedParamsApi {
  code?: string,
  clusters?: number,
  start_datetime_after?: string,
  start_datetime_before?: string,
  event_type?: string,
  name?: string,
  ordering?: string,
  phase?: string,
  reduced_state?: REDUCED_STATES,
  search_by_code?: string,
  search_by_name?: string,
  state?: EVENT_STATES,
  q?: string,
}

export type CreateEventParams = {
  'event_type': string,
  'name': string,
  'description'?: string,
  'ancestor_event'?: string,
  'forecast_start_datetime'?: string,
  'start_datetime': string,
  'end_datetime'?: string,
  'state': EVENT_STATES.Forecast | EVENT_STATES.Happened
}

export type PatchEventParams = Partial<Omit<GetEvent, 'uuid'>> & { uuid: string }

export type GetClusterPolygon = {
  'cluster_template': string,
  'cluster_polygons': ({
    'alert_polygon_uuid': string | null,
    'entity_uuid': string | null
    'globalpolygon': string,
    'polygon_name': string | null,
    'uuid': string,
  })[]
}

export type GetClusterAsset = {
  'cluster_template': string,
  'cluster_assets': AssetMarker[]
}

export type ClusterPolygonPostParams = {
    event_uuid: string
  }
  &
  (
    { cluster_template: string | null, globalpolygon: string, geoshape: null }
    |
    { cluster_template: string | null, globalpolygon: null, geoshape: NewMultiPolygonGeometry })

export type ClusterPolygonPostResult =
  {
    'alert_polygon_uuid': string | null,
    'assets': AssetMarker[],
    'cluster_template': string | null,
    'entity_uuid': string | null,
    'globalpolygon': string,
    'polygon_name': null,
  }

export type UpdateAssetClusterPost = {
  'cluster_template': string | null,
  'assets': string[],
  'text_note': string | null
}

export type RemoveClusterResponse = {
  'assets': string[],
  'globalpolygons': string[],
  'entities': string[]
}

export interface GetCheckAssetsChangesParams extends GenericPaginatedParamsApi {
  eventUuid: string;
}

export type CheckAssetsChangesResponse = {
  'asset': AssetAddress,
  'old_cluster_template': string | null,
  'new_cluster_template': string | null,
  'old_configuration_datetime': string | null,
  'text_note': string | null
}

export type GetCheckAssetsChangesResponse = GenericPaginatedApi<CheckAssetsChangesResponse>

export type ConfigurationHistory = {
  'assets_count': number,
  'clusters_count': number
  'detail':
    {
      'cluster_template': string,
      'assets': [
        {
          'state': ASSET_STATUS,
          'count': number
        }
      ],
      'assets_count': number
    }[],
}

export type GetEventHistory = {
  'uuid': string,
  'operation_date': string,
  'configuration': ConfigurationHistory,
  'changes': {
    'assets': number,
    'asset_state': number,
    'clusters': number
  },
  'user_edit': string,
  'text_note': string | null
}

export interface GetEventHistoryParams extends GenericPaginatedParamsApi {
  eventUuid: string;
}

export type GetEventHistoryResponse = GenericPaginatedApi<GetEventHistory>

export interface GetEventHistoryPerAssetParams extends GenericPaginatedParamsApi {
  eventUuid: string,
  is_state_change?: boolean,
  operation_date?: string,
  q?: string
}

export type GetEventHistoryPerAsset = {
  'asset': AssetAddress,
  'operation_date': string,
  'change':
    {
      'state_from': ASSET_STATUS | null,
      'state_to': ASSET_STATUS | null
    }
    |
    {
      'cluster_template_from': string | null,
      'cluster_template_to': string | null
    },
  'text_note': string | null,
  'user_edit': string,
  'uuid': string
}

export type GetEventHistoryPerAssetResponse = GenericPaginatedApi<GetEventHistoryPerAsset>

export type GetEventDetailHistory = {
  'configuration':
    {
      assets: AssetAddress[],
      cluster_template: string
    }[]
  
  'operation_date': string,
  'text_note': string | null,
  'user_edit': string,
  'uuid': string,
}

export interface GetAssetStatusParams extends GenericPaginatedParamsApi {
  eventUuid: string,
  date?: string,
  changes?: CHANGES;
  q?: string,
  state?: ASSET_STATUS,
  address?: string,
  admin_level_1?: string,
  admin_level_2?: string,
  admin_level_3?: string,
  building_type?: string,
  country?: string,
  postal_code?: string,
  sigi_code?: string,
  
  address_not?: string,
  admin_level_1_not?: string,
  admin_level_2_not?: string,
  admin_level_3_not?: string,
  building_type_not?: string,
  country_not?: string,
  sigi_code_not?: string,
  state_not?: ASSET_STATUS,
}

export type EventAssetNoteType = {
    "uuid": string,
    "text": string,
    "assets_count": number,
    "user_edit": string,
    "date_creation": string
}

export type GetAssetStatus = {
  'asset': AssetAddress,
  'state': ASSET_STATUS,
  'state_from': null | ASSET_STATUS,
  'notes': EventAssetNoteType | null
}

export type InvolvedAssetsRelatedOptionsFilterParams = {
  column?: string,
  column_search?: string,
  eventUuid: string,
} & GenericPaginatedParamsApi

export type GetAssetStatusResponse = GenericPaginatedApi<GetAssetStatus>

export type GetAssetsOfTheHistoryOfTheEvent = {
  asset: AssetAddress,
  cluster_template: string | null,
}

export type GetAssetsOfTheHistoryOfTheEventResponse = GenericPaginatedApi<GetAssetsOfTheHistoryOfTheEvent>

export interface GetAssetsOfTheHistoryOfTheEventParams extends GenericPaginatedParamsApi {
  eventUuid: string,
}

export type GetClusterOfTheHistoryOfTheEvent = {
  cluster_template: string,
  assets_count: number,
  draft_count: number,
  sent_count: number,
}

export type GetEventsMap = {
  'uuid': string,
  'color_code': string,
  'event_type': string,
  'position': { lat: number, lng: number }[]
}