export enum User {
  ADMIN = 'IHSE - ADMIN',
  SUPERVISOR = 'IHSE - SUPERVISOR',
  'ENV&ENE' = 'IHSE - ENV&ENE',
  EMERGENCY = 'IHSE - EMERGENCY',
  GENERIC = 'IHSE - GENERIC',
  GUEST = 'IHSE - GUEST'
}

const ALL_USERS = Object.values(User);

const isUserAuthorized = (user: User, usersAuthorized: User[]) => usersAuthorized.includes(user);

// USER CREATED ASSET
export const isUserCreatedAssetCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY]);
export const isUserCreatedAssetEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);
export const isUserCreatedAssetDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

// GEOLOCATION AUTOMATIC ASSET
export const isGeolocationAutomaticAssetEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

// ASSET DOCUMENTS
export const isAssetDocumentView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isAssetDocumentCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY]);
export const isAssetDocumentEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);
export const isAssetDocumentDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

// ASSET IMAGES
export const isAssetImageView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isAssetImageCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);
export const isAssetImageEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);
export const isAssetImageDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

//REPORTS
export const isReportsCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);

// EVENT TYPES
export const isEventTypesView = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);
export const isEventTypesCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventTypesEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventTypesDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);

// EVENT TYPE DOCUMENT
export const isEventTypeDocumentView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isEventTypeDocumentCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventTypeDocumentEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventTypeDocumentDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

// TEMPLATE MAIL
export const isTemplateMailView = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);
export const isTemplateMailCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isTemplateMailEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isTemplateMailDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

// LAYER MAP
export const isLayerMapView = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);
export const isLayerMapCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isLayerMapEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isLayerMapDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR]);

// EVENT DOCUMENT
export const isEventDocumentView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isEventDocumentCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventDocumentEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventDocumentDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);

// GENERIC DOCUMENT
export const isGenericDocumentView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isGenericDocumentCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY]);
export const isGenericDocumentEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY]);
export const isGenericDocumentDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE']]);

// ALERT
export const isAlertView = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);
export const isAlertCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isAlertEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isAlertDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isAlertArchive = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isAssignAlertToEvent = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isDeassignAlertToEvent = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);

// ALERT DOCUMENT
export const isAlertDocumentView = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY, User['ENV&ENE']]);
export const isAlertDocumentCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isAlertDocumentEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isAlertDocumentDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);

// EVENT
export const isEventView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isEventCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventArchive = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);

// EVENT COMUNICATION
export const isEventCommunicationCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventCommunicationEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);
export const isEventCommunicationDelete = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User.EMERGENCY]);

// MITIGATION ACTIONS
export const isMitigationActionsCreation = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);
export const isMitigationActionsEdit = (user: User) => isUserAuthorized(user, [User.ADMIN, User.SUPERVISOR, User['ENV&ENE'], User.EMERGENCY, User.GENERIC]);

// DATA SOURCES
export const isDataSourcesView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isDataSourcesEdit = (user: User) => isUserAuthorized(user, [User.ADMIN]);

// IMPORT DATA
export const isImportDataView = (user: User) => isUserAuthorized(user, [User.ADMIN]);
export const isImportDataEdit = (user: User) => isUserAuthorized(user, [User.ADMIN]);

// LIST
export const isAssetsListView = (user: User) => isUserAuthorized(user, ALL_USERS);
export const isDocumentsListView = (user: User) => isUserAuthorized(user, ALL_USERS);