import {Alert, Avatar, Button, Col, Layout, Menu, MenuProps, Row, Typography} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {
  AlertOutlined,
  BookOutlined,
  FileOutlined,
  PushpinOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import riskappLogo from '../../assets/images/riskapp_logo.png';
import {Link, useMatches, useNavigate} from 'react-router-dom';
import {HeaderItem} from '../../types/internal/HeaderItems';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {invertProfileVisibility} from '../../redux/slices/layout';
import {useDispatch} from 'react-redux';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import {
  isAlertView,
  isAssetsListView,
  isDataSourcesView,
  isDocumentsListView,
  isEventCreation,
  isEventTypesView,
  isEventView,
  isImportDataView,
  isLayerMapView,
} from '../Authentication/permissions';
import getIconAccordingToId from '../../routing/getIconAccordingToId';
import PermissionRoute from '../Authentication/PermissionRoute';

const {Header} = Layout;
const {Text} = Typography;
export default function CustomHeader(props: { isSticky?: boolean }) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState<HeaderItem | null>(null);

  const menuRef = useRef(null);
  const matches = useMatches();

  useEffect(() => {
    const icon = getIconAccordingToId(matches[matches.length - 1].id);
    setCurrent(icon);
  }, [matches]);

  const {
    data: userData,
    isFetching: isFetchingUserData,
  } = useGetUserProfileQuery();

  const isAssets = userData?.type ? isAssetsListView(userData?.type) : false;
  const isDocuments = userData?.type ? isDocumentsListView(userData?.type) : false;
  const isAlerts = userData?.type ? isAlertView(userData?.type) : false;
  const isEvents = userData?.type ? isEventView(userData?.type) : false;
  const isSettings = userData?.type ? (isEventTypesView(userData.type) || isLayerMapView(userData.type) || isDataSourcesView(userData.type) || isImportDataView(userData.type)) : false;

  const items: MenuProps['items'] = [
    ...isAssets ? [{
      key: 'assets',
      label: capitalize(t('assets.asset', {count: 2})),
      icon: <UnorderedListOutlined/>,
    }] : [],
    ...isAlerts ? [{
      key: 'alerts',
      label: capitalize(t('alerts.list')),
      icon: <WarningOutlined/>,
    }] : [],
    ...isEvents ? [{
      key: 'events',
      label: capitalize(t('events.list')),
      icon: <BookOutlined/>,
    }] : [],
    {
      key: 'map',
      label: capitalize(t('map.map')),
      icon: <PushpinOutlined/>,
    },
    ...isDocuments ? [{
      key: 'documents',
      label: capitalize(t('documents.document', {count: 2})),
      icon: <FileOutlined/>,
    }] : [],
    ...isSettings ? [{
      key: 'settings',
      label: capitalize(t('settings.settings')),
      icon: <SettingOutlined/>,
    }] : [],
    {
      key: 'user',
      label: capitalize(t('misc.userProfile')),
      icon: <UserOutlined/>,
      theme: 'dark',
    },
  ];

  const onClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case 'user':
        dispatch(invertProfileVisibility());
        break;
      default:
        navigate(`/${e.key}`);
        break;
    }
    setCurrent(e.key as HeaderItem);
  };

  return (<>
      <Header
        style={{
          position: props.isSticky || true ? 'sticky' : 'relative',
          top: 0,
          zIndex: 10000,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{flexGrow: 1}}>
          <Row
            align={'middle'}
            wrap={false}
            gutter={[12, 12]}
          >
            <Col>
              <Row
                align={'middle'}
                wrap={false}
                gutter={[8, 8]}
              >
                <Col style={{display: 'flex'}}>
                  <Avatar src={riskappLogo} style={{backgroundColor: '#FFF'}}/>
                </Col>
                <Col style={{display: 'flex'}}>
                  <Text style={{fontSize: 18, fontWeight: 700, width: '85px', color: '#FFF'}}>
                    RiskApp
                  </Text>
                </Col>
              </Row>
            </Col>
            <PermissionRoute isAuthorized={isEventCreation}>
              <Col>
                <Link
                  to={'/events/create'}
                >
                  <Button
                    type={'primary'}
                    danger={true}
                  >
                    <Row justify={'space-around'}>
                      <Col>
                        <AlertOutlined/>
                      </Col>
                      <Col
                        xs={0}
                        sm={20}
                      >
                        {capitalize(t('actions.insertItem', {item: t('events.event', {count: 1})}))}
                      </Col>
                    </Row>
                  </Button>
                </Link>
              </Col>
            </PermissionRoute>
          </Row>
        </div>
        <Menu
          theme={'light'}
          style={{minWidth: 0, flex: 'auto', justifyContent: 'end', height: '100%'}}
          selectedKeys={current ? [current] : []}
          mode="horizontal"
          items={items}
          onClick={onClick}
        />
        <div ref={menuRef}></div>
      </Header>
      {process.env.REACT_APP_SHOW_BANNER_TEST_ENVIRONMENT === 'show' && <Alert
        message={t('misc.testEnvironment')}
        type={"warning"}
        banner
      />}
    </>

  );
}