import {Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useEventContext} from '../../../context/event/EventContext';
import {EVENT_STATES} from "../../../types/api/getEventsResult";
import {usePatchEventMutation} from "../../../redux/api/events";
import {CloseEventModal} from "./CloseEventModal";

export default function EventHappenedModal() {

  const {t} = useTranslation();
  const {event} = useEventContext();
  const [updateEvent] = usePatchEventMutation()

  return (
    <CloseEventModal
      modalTitle={capitalize(t('events.closeAndArchive'))}
      modalDescription={<Typography.Text>
        {t('events.archiveEventDescription')}
      </Typography.Text>}
      dateLabel={capitalize(t('events.dateEndEvent'))}
      modalButton={{
        type: "primary",
        danger: true,
        icon: undefined,
        children: capitalize(t('events.closeAndArchive')),
      }}
      onFinish={v => {
        event && updateEvent({
          uuid: event.uuid,
          state: EVENT_STATES.Closed,
          end_datetime: v
        })
      }}
    />
  );
}