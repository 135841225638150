import PermissionRoute from "../../Authentication/PermissionRoute";
import {isEventEdit} from "../../Authentication/permissions";
import {Button, ButtonProps, Divider, Form, Input, Modal, Select, Space, Typography} from "antd";
import {capitalize} from "lodash";
import {ASSET_STATUS, GetAssetStatus} from "../../../types/api/getEventsResult";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useEventContext} from "../../../context/event/EventContext";
import {usePostEventAssetStatusMutation} from "../../../redux/api/events";
import {Trans, useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import EditButton from "../../common/buttons/EditButton";
import {titleCase} from "../../../utils/parser";

export default function AssetStatusEdit({assets, modalButtonProps}: {
  assets: GetAssetStatus[],
  modalButtonProps: ButtonProps
}) {

  const setMessage = useMessageContext()
  const {event} = useEventContext()
  const [updateAssetStatus, {isLoading}] = usePostEventAssetStatusMutation()
  const {t} = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const firstAsset = assets.find((el, idx) => idx === 0)
  const firstAssetState = firstAsset?.state
  const isOne = assets.length === 1
  const isMoreThanOne = assets.length > 1

  const onSubmit = useCallback(async (values: { state: ASSET_STATUS, note?: string }) => {
    if (event) {

      const assetsToChange = assets.filter(asset => asset.state !== values.state);

      try {
        await updateAssetStatus({
          eventUuid: event?.uuid,
          assets: assetsToChange.map(el => el.asset.uuid),
          text_note: values.note || null,
          state: values.state
        }).unwrap()
        setMessage({success: capitalize(t('form.editSuccessMessage'))});
      } catch {
        setMessage({error: capitalize(t('form.editFailMessage'))});
      } finally {
        setIsModalOpen(false)
      }
    }
  }, [assets, event, setMessage, t, updateAssetStatus])

  return <PermissionRoute isAuthorized={isEventEdit}>
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={false}
      title={capitalize(t('actions.editItem', {item: t('assets.openState')}))}
      destroyOnClose
    >
      {isMoreThanOne &&
        <Typography.Text>
          <Trans
            i18nKey={'assets.selected'}
            values={{
              number: assets.length,
            }}
            components={{p: <Typography.Text/>}}
            count={assets.length}
          />
        </Typography.Text>}

      {isOne && <Space direction={'vertical'} size={0}>
        <Typography.Text strong>#{firstAsset?.asset.sigi_code}</Typography.Text>
        <Typography.Text>
          {titleCase([
            firstAsset?.asset.address ? capitalize(firstAsset?.asset.address) : null,
            firstAsset?.asset.admin_level_3 ? capitalize(firstAsset?.asset.admin_level_3) : null,
            firstAsset?.asset.admin_level_1 ? capitalize(firstAsset?.asset.admin_level_1) : null,
          ].filter(string => string !== null)
            .join(', '))}
        </Typography.Text>
      </Space>}
      <Divider/>
      <Form
        onFinish={onSubmit}
        initialValues={{
          ...isOne && {state: firstAssetState}
        }}
        layout={'vertical'}
        style={{marginTop: '1rem'}}
      >
        <Form.Item
          label={capitalize(t('assets.openState'))}
          name={'state'}
          rules={[
            {
              required: true,
              message: capitalize(t('form.requiredField', {field: t('assets.openState')})),
            },
          ]}
        >
          <Select
            style={{width: '15rem', textAlign: 'center'}}
            loading={isLoading}
            disabled={isLoading}
            options={[
              {
                label: capitalize(t('assets.statusClosed', {count: 1})),
                value: ASSET_STATUS.CLOSED,
              }, {
                label: capitalize(t('assets.statusRemote')),
                value: ASSET_STATUS.REMOTE_WORK,
              }, {
                label: capitalize(t('assets.statusOpen', {count: 1})),
                value: ASSET_STATUS.OPEN,
              }, {
                label: capitalize(t('assets.statusClosedRemote', {count: 1})),
                value: ASSET_STATUS.CLOSED_REMOTE_WORK,
              }]}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => prevValues.state !== curValues.state}
        >
          {(form) => {
            const showNotes = isMoreThanOne || form.getFieldValue('state') !== firstAssetState
            return showNotes && <Form.Item
              name={'note'}
              label={capitalize(t('misc.note'))}
            >
              <Input.TextArea
                autoSize={{minRows: 4}}
              />
            </Form.Item>
          }}

        </Form.Item>
        <Form.Item
          style={{
            textAlign: 'right',
            marginTop: '1rem',
          }}
          shouldUpdate={(prevValues, curValues) => prevValues.state !== curValues.state}
        >
          {(form) => {
            const disabledBtn =
              !form.getFieldValue('state') ||
              isLoading ||
              (isOne && form.getFieldValue('state') === firstAssetState)
            return <Button
              type={'primary'}
              htmlType={'submit'}
              loading={isLoading}
              disabled={disabledBtn}
              onClick={() => onSubmit(form.getFieldsValue())}
            >
              {capitalize(t('actions.confirm'))}
            </Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
    <EditButton
      onClick={() => setIsModalOpen(true)}
      {...modalButtonProps}
    />
  </PermissionRoute>
}