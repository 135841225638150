import {Button, ButtonProps, DatePickerProps, Form, FormItemProps, Modal, ModalProps, Row} from "antd";
import {EditOutlined} from "@ant-design/icons";
import dayjs, {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";
import {ReactNode, useState} from "react";
import {capitalize} from "lodash";
import AntFormItem from "../../common/AntFormItem";
import {LocalizedDatePicker} from "../../common/LocalizedDatePicker";

export default function DateTimeModal({formItemProps, datePickerProps, onFinish, description, modal, modalButton, onFinishButton, initialValue}: {
  onFinish: (date: string) => void,
  modal?: ModalProps,
  modalButton?: ButtonProps,
  onFinishButton?: ButtonProps,
  description?: ReactNode,
  formItemProps?: FormItemProps,
  datePickerProps?: DatePickerProps,
  initialValue?: dayjs.Dayjs,
}) {

  const {t} = useTranslation();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  const [form] = Form.useForm()

  const handleFinish = ({date}: { date?: Dayjs }) => {
    setIsModalOpen(false)
    date && onFinish(date.toISOString())
  }


  return <>
    <Modal
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={<Row justify={'end'}>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item required>
            <Button
              type={'primary'}
              htmlType={'submit'}
              children={capitalize(t('actions.save'))}
              {...onFinishButton}
            />
          </Form.Item>
        </Form>
      </Row>}
      {...modal}
    >
      {!!description && <div style={{marginTop: '1rem'}}>
            {description}
      </div>}

      <Form form={form} layout={'vertical'} style={{margin: '1rem 0'}} initialValues={{date: initialValue}}>
        <AntFormItem
          label={capitalize(t('misc.date'))}
          name={'date'}
          {...formItemProps}
        >
          <LocalizedDatePicker
            style={{minWidth: '10rem'}}
            {...datePickerProps}
          />
        </AntFormItem>
      </Form>
    </Modal>
    <Button
      type={'text'}
      icon={<EditOutlined/>}
      onClick={(e) => {
        modalButton?.onClick && modalButton.onClick(e)
        setIsModalOpen(true)
      }}
      {...modalButton}
    />
  </>
}