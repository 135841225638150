import {useGetEventsNotPaginatedQuery} from '../../../redux/api/events';
import {GetEvent, REDUCED_STATES} from '../../../types/api/getEventsResult';
import {capitalize} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Card, Col, List, Row, Space, Typography} from 'antd';
import EventDetailDrawer from './EventDetailDrawer';
import EventTypeFromUuid from "../../common/icons/EventTypeFromUuid";
import DeleteEventButton from "../DeleteEventButton";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import PhaseTag from "../../event/PhaseTag";
import EventStateTag from "./EventStateTag";
import GrecimTag from "./GrecimTag";

export default function ActiveEventsList() {

  const {t} = useTranslation();
  const {
    data,
    isFetching,
    isLoading,
    isUninitialized,
  } = useGetEventsNotPaginatedQuery({reduced_state: REDUCED_STATES.active}, {refetchOnMountOrArgChange: true});

  const loading = isLoading || isFetching || isUninitialized;
  const [eventDrawer, setEventDrawer] = useState<GetEvent | null>(null);

  useEffect(() => {

    return () => {
      setEventDrawer(null);
    };
  }, []);

  return <>
    {!!eventDrawer && <EventDetailDrawer
      event={eventDrawer}
      drawer={{
        open: !!eventDrawer,
        onClose: () => setEventDrawer(null),
      }}
    />}
    <List
      dataSource={data}
      loading={loading}
      renderItem={(event) => {
        return <Card style={{marginBottom: '1rem'}}>
          <List.Item>
            <List.Item.Meta
              title={<div style={{display: "flex", flexDirection: 'column', gap: '0.2rem', fontWeight: 'normal'}}>
                <Typography.Title ellipsis={{tooltip: true}} level={3} style={{margin: 0}}>
                  {event.name}
                </Typography.Title>
                <Typography.Text type={'secondary'}>
                  #{event.code}
                </Typography.Text>
                <div>
                  <EventStateTag eventState={event.state}/><DateTimeFormat>{event.start_datetime}</DateTimeFormat>
                </div>

              </div>}
              avatar={<EventTypeFromUuid
                space={{direction: 'vertical'}}
                title={{ellipsis: {tooltip: true}, style: {width: '7rem', textAlign: 'center', margin: 0}}}
                uuid={event.event_type}
                iconStyle={{
                  width: '2rem',
                  height: '2rem',
                  backgroundColor: event.color_code
                }}/>}
            />
          </List.Item>
          <Row style={{width: '100%', marginTop: '0.5rem'}} gutter={[24, 24]} align={'middle'} justify={'end'}>
            <Col>
              <Space direction={'vertical'} align={'end'}>
                <PhaseTag phase={event?.phase}/>
                {!!event?.is_special_management && <GrecimTag/>}
              </Space>
            </Col>
            <Col>
              {`${event.involved_assets} ${t('events.involvedAssets')}`}
            </Col>
            <Col>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography.Text strong>{capitalize(t('misc.lastUpdated'))}:</Typography.Text>
                <DateTimeFormat>
                  {event.last_update}
                </DateTimeFormat>
              </div>
            </Col>
            <Col>
              <Space wrap>
                <Button type={'primary'} onClick={() => setEventDrawer(event)}>
                  {capitalize(t('misc.goToDetail'))}
                </Button>
                <DeleteEventButton selectedEvents={[event.uuid]}/>
              </Space>
            </Col>
          </Row>
        </Card>
      }}
    />
  </>;
}