import {ReactNode, useState} from 'react';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Col, Layout, Row, Space, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

const {Sider} = Layout;
const {Title} = Typography;
export const CustomSider = (props: {
  title?: string,
  backButtonLabel?: string,
  content?: ReactNode,
  destination?: string,
  summaryCard?: ReactNode
}) => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [
    isSidebarCollapsed,
    setIsSidebarCollapsed,
  ] = useState(false);

  return (
    <Sider
      width={300}
      theme={'light'}
      breakpoint={'lg'}
      collapsed={isSidebarCollapsed}
      onCollapse={collapsed => setIsSidebarCollapsed(collapsed)}
      collapsedWidth={0}
      zeroWidthTriggerStyle={{backgroundColor: '#1890FF'}}
      trigger={isSidebarCollapsed ?
        <RightOutlined style={{fontSize: 18, color: '#fff'}}/> :
        <LeftOutlined style={{fontSize: 18, color: '#fff'}}/>}
      style={{padding: '1.5rem 0rem'}}
    >
      <div style={{height: `calc( 100dvh - ${process.env.REACT_APP_SHOW_BANNER_TEST_ENVIRONMENT === 'show' ? "88px" : "50px"} - 2rem)`, overflow: 'auto'}}>
        <Row
        justify={'space-between'}
        style={{height: '100%', display: 'flex'}}
      >
        <Col flex={1}>
          <Space
            size={'large'}
            direction={'vertical'}
            style={{display: 'flex'}}
          >
            <div style={{padding: '0 1.5rem'}}>
              <Button
                type={'text'}
                style={{paddingLeft: 0}}
                icon={<LeftOutlined/>}
                onClick={() => props.destination ? navigate(props.destination) : navigate(-1)}
                children={isSidebarCollapsed ? null : props.backButtonLabel || capitalize(t('actions.goBack'))}
              />
            </div>
            {!isSidebarCollapsed && props.title && (
              <Title level={5} style={{marginTop: 0, marginLeft: '1rem'}}>
                {props.title}
              </Title>
            )}
            {props.content && props.content}
          </Space>
        </Col>
        {!isSidebarCollapsed && props.summaryCard && (
          <Col
            flex={1}
            style={{display: 'flex', alignItems: 'end', padding: '0 1.5rem'}}
          >
            {props.summaryCard}
          </Col>
        )}
      </Row>
      </div>
    </Sider>
  );
};