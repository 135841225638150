// Need to use the React-specific entry point to import createApi
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import {RootState} from '../store';
import {Mutex} from 'async-mutex';
import {getRefreshToken, REDIRECT_TO} from '../../components/Authentication/authUtils';
import {setAccessToken} from '../slices/auth';
import {QueryReturnValue} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {router} from '../../routing/Router';
import {setMessage} from '../slices/messages';
import i18next from 'i18next';
import _ from 'lodash';

// create a new mutex
const mutex = new Mutex();

const endpointsWithoutRefreshTokenRequest = ['login', 'refreshToken', 'getGeoCode', 'getReverseGeocode', 'getLegendGraphic', 'getFeatureInfo', 'fileUploadToS3'];
const genericDebounce = _.debounce((func) => func(), 100);

const baseQuery = fetchBaseQuery({
  baseUrl: process.env['REACT_APP_API_URL'],
  credentials: 'same-origin',
  prepareHeaders: (headers, {getState, endpoint}) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.accessToken;
    if (token && !endpointsWithoutRefreshTokenRequest.includes(endpoint)) {
      headers.set('authorization', `JWT ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  
  if (!endpointsWithoutRefreshTokenRequest.includes(api.endpoint) && result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: 'auth/refresh-token/',
            method: 'POST',
            body: {
              refresh: getRefreshToken(),
            },
          },
          api,
          extraOptions) as QueryReturnValue<{ access: string }, FetchBaseQueryError, FetchBaseQueryMeta>;
        if (refreshResult.data) {
          // store the new token
          api.dispatch(setAccessToken({token: refreshResult.data.access}));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          sessionStorage.setItem(REDIRECT_TO, router.state.location.pathname);
          api.dispatch(setMessage({error: i18next.t('misc.unauthorizedError')}));
          router.navigate('/logout');
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  
  if (result.error && (result.error.status === 500 || result.error.status === 'PARSING_ERROR')) {
    genericDebounce(() => api.dispatch(setMessage({error: i18next.t('misc.500error')})));
  }
  
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Alerts',
    'AlertGroups',
    'AlertLogs',
    'AlertSource',
    'Asset',
    'AssetsDocuments',
    'AssetsImages',
    'AssetStatus',
    'ClusterTemplates',
    'ClusterTemplatesTranslations',
    'Contacts',
    'Documents',
    'Events',
    'EventCommunications',
    'EventConfiguration',
    'EventTypes',
    'EventTypesTranslations',
    'EventTypesDocuments',
    'Layers',
    'Locale',
    'Notes',
    'TemplateEmail',
    'User',
  ],
  endpoints: builder => ({}),
  // endpoints:
});