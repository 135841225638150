import {AssetsTableStaticColumn} from '../types/internal/tables/assetsTable';
import {AlertsColumns} from '../types/internal/tables/alertsTable';
import {ASSET_STATUS} from '../types/api/getEventsResult';
import {InvolvedAssetsTableColumn} from '../types/internal/tables/involvedAssetsTable';

export const VALUE_NOT_PRESENT = 'n/a';

export const getAssetStatusTranslationKey = ({state, reduced}: { state: ASSET_STATUS, reduced?: boolean }) => {
  let key;
  switch (state) {
    case ASSET_STATUS.OPEN:
      key = reduced ? 'assets.statusOpenReduced' : 'assets.statusOpen';
      break;
    case ASSET_STATUS.REMOTE_WORK:
      key = reduced ? 'assets.statusRemoteReduced' : 'assets.statusRemote';
      break;
    case ASSET_STATUS.CLOSED:
      key = reduced ? 'assets.statusClosedReduced' : 'assets.statusClosed';
      break;
    case ASSET_STATUS.CLOSED_REMOTE_WORK:
      key = reduced ? 'assets.statusClosedRemoteReduced' : 'assets.statusClosedRemote';
      break;
  }
  return key;
};

export function getTranslationAssetTableColumnKey(column: AssetsTableStaticColumn) {
  switch (column) {
    case 'address':
      return 'address.address';
    case 'admin_level_1':
      return 'address.region';
    case 'admin_level_2':
      return 'address.province';
    case 'admin_level_3':
      return 'address.municipality';
    case 'biodiversity_within':
      return 'assets.biodiversity';
    case 'building_type':
      return 'assets.buildingType';
    case 'building_type_detail':
      return 'assets.buildingTypeDetail';
    case 'closing_date':
      return 'assets.closingDate';
    case 'companies':
      return 'assets.companies';
    case 'contact_people':
      return 'contacts.contacts';
    case 'country':
      return `address.country`;
    case 'is_user_created':
      return 'assets.isUserCreatedTitle';
    case 'ownership':
      return 'assets.isOwned';
    case 'map_view':
      return 'map.viewMap';
    case 'master_asset':
      return 'assets.masterAsset';
    case 'opening_date':
      return 'assets.openingDate';
    case 'postal_code':
      return `address.postalCode`;
    case 'realestate_garrison':
      return `assets.realestateGarrisonName`;
    case 'realestate_hub':
      return `assets.realestateHubName`;
    case 'sigi_code':
      return 'assets.sigiCode';
    case 'surface_basement':
      return 'assets.surfaceBasement';
    case 'surface_total':
      return 'assets.surfaceTotal';
    case 'total_resources':
      return 'assets.totalResources';
    default:
      return 'HAZARD';
  }
}

export function getTranslationAlertToManageTableColumnKey(column: AlertsColumns) {
  switch (column) {
    case 'event':
      return 'events.event';
    case 'is_user_created':
      return 'alerts.creationMode';
    case 'source_url':
      return 'alerts.datasource';
    case 'end_datetime':
      return 'alerts.endDate';
    case 'title':
      return 'alerts.title';
    case 'event_type':
      return 'alerts.eventType';
    case 'assets_count':
      return 'alerts.involvedAssets';
    case 'geographic_area_list':
      return 'alerts.geoArea';
    case 'is_forecast':
      return 'events.forecastOrHappened';
    case 'start_datetime':
      return 'alerts.startDate';
  }
}

export function getTranslationInvolvedAssetTableColumnKey(column: InvolvedAssetsTableColumn) {
  switch (column) {
    case 'address':
      return 'address.address';
    case 'admin_level_1':
      return 'address.region';
    case 'admin_level_2':
      return 'address.province';
    case 'admin_level_3':
      return 'address.municipality';
    case 'building_type':
      return 'assets.buildingType';
    case 'country':
      return `address.country`;
    case 'sigi_code':
      return 'assets.sigiCode';
    case 'state':
      return 'events.changeAssetsState';
  }
}